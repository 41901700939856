// @ts-nocheck
/* eslint-disable */

enum SelectingTimes {
  Hour = 1,
  Minute = 2,
  Second = 3
}

export { SelectingTimes }
